// @ts-nocheck
import * as Types from '~/@types/generated/subscription/graphql-schema-types';

import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type ReactiveFunction<TParam> = () => TParam;

export const DrawDateChangesDocument = gql`
    subscription drawDateChanges($licensedTerritory: String!) {
  drawDateChanges(licensedTerritory: $licensedTerritory) {
    cause
    lotteryId
  }
}
    `;

/**
 * __useDrawDateChangesSubscription__
 *
 * To run a query within a Vue component, call `useDrawDateChangesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDrawDateChangesSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useDrawDateChangesSubscription({
 *   licensedTerritory: // value for 'licensedTerritory'
 * });
 */
export function useDrawDateChangesSubscription(variables: Types.DrawDateChangesSubscriptionVariables | VueCompositionApi.Ref<Types.DrawDateChangesSubscriptionVariables> | ReactiveFunction<Types.DrawDateChangesSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<Types.DrawDateChangesSubscription, Types.DrawDateChangesSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<Types.DrawDateChangesSubscription, Types.DrawDateChangesSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<Types.DrawDateChangesSubscription, Types.DrawDateChangesSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<Types.DrawDateChangesSubscription, Types.DrawDateChangesSubscriptionVariables>(DrawDateChangesDocument, variables, options);
}
export type DrawDateChangesSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<Types.DrawDateChangesSubscription, Types.DrawDateChangesSubscriptionVariables>;
export const OrderStateChangesDocument = gql`
    subscription orderStateChanges($accessToken: String!) {
  orderStateChanges(authToken: $accessToken) {
    reference
    state
  }
}
    `;

/**
 * __useOrderStateChangesSubscription__
 *
 * To run a query within a Vue component, call `useOrderStateChangesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrderStateChangesSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useOrderStateChangesSubscription({
 *   accessToken: // value for 'accessToken'
 * });
 */
export function useOrderStateChangesSubscription(variables: Types.OrderStateChangesSubscriptionVariables | VueCompositionApi.Ref<Types.OrderStateChangesSubscriptionVariables> | ReactiveFunction<Types.OrderStateChangesSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<Types.OrderStateChangesSubscription, Types.OrderStateChangesSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<Types.OrderStateChangesSubscription, Types.OrderStateChangesSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<Types.OrderStateChangesSubscription, Types.OrderStateChangesSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<Types.OrderStateChangesSubscription, Types.OrderStateChangesSubscriptionVariables>(OrderStateChangesDocument, variables, options);
}
export type OrderStateChangesSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<Types.OrderStateChangesSubscription, Types.OrderStateChangesSubscriptionVariables>;
export const PaymentStateChangesDocument = gql`
    subscription paymentStateChanges($accessToken: String!) {
  paymentStateChanges(authToken: $accessToken) {
    reference
    state
    type
  }
}
    `;

/**
 * __usePaymentStateChangesSubscription__
 *
 * To run a query within a Vue component, call `usePaymentStateChangesSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePaymentStateChangesSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = usePaymentStateChangesSubscription({
 *   accessToken: // value for 'accessToken'
 * });
 */
export function usePaymentStateChangesSubscription(variables: Types.PaymentStateChangesSubscriptionVariables | VueCompositionApi.Ref<Types.PaymentStateChangesSubscriptionVariables> | ReactiveFunction<Types.PaymentStateChangesSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<Types.PaymentStateChangesSubscription, Types.PaymentStateChangesSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<Types.PaymentStateChangesSubscription, Types.PaymentStateChangesSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<Types.PaymentStateChangesSubscription, Types.PaymentStateChangesSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<Types.PaymentStateChangesSubscription, Types.PaymentStateChangesSubscriptionVariables>(PaymentStateChangesDocument, variables, options);
}
export type PaymentStateChangesSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<Types.PaymentStateChangesSubscription, Types.PaymentStateChangesSubscriptionVariables>;
export const PlayerSignupFinishedDocument = gql`
    subscription playerSignupFinished($accessToken: String!) {
  playerSignupFinished(authToken: $accessToken) {
    playerId
  }
}
    `;

/**
 * __usePlayerSignupFinishedSubscription__
 *
 * To run a query within a Vue component, call `usePlayerSignupFinishedSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePlayerSignupFinishedSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = usePlayerSignupFinishedSubscription({
 *   accessToken: // value for 'accessToken'
 * });
 */
export function usePlayerSignupFinishedSubscription(variables: Types.PlayerSignupFinishedSubscriptionVariables | VueCompositionApi.Ref<Types.PlayerSignupFinishedSubscriptionVariables> | ReactiveFunction<Types.PlayerSignupFinishedSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<Types.PlayerSignupFinishedSubscription, Types.PlayerSignupFinishedSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<Types.PlayerSignupFinishedSubscription, Types.PlayerSignupFinishedSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<Types.PlayerSignupFinishedSubscription, Types.PlayerSignupFinishedSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<Types.PlayerSignupFinishedSubscription, Types.PlayerSignupFinishedSubscriptionVariables>(PlayerSignupFinishedDocument, variables, options);
}
export type PlayerSignupFinishedSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<Types.PlayerSignupFinishedSubscription, Types.PlayerSignupFinishedSubscriptionVariables>;